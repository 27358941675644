import React, { Component } from "react"
import { FormattedMessage } from "react-intl"
import LocalizedLink from "../LocalizedLink"
import NavbarIcons from "./NavbarIcons"
import NavbarLangSwitch from "./NavbarLangSwitch"
import styled from "styled-components"
import { styles } from "../../globals"

export default class NavbarLinks extends Component {
  state = {
    links: [
      {
        id: 0,
        path: "/",
        name: <FormattedMessage id="home" />,
      },
      {
        id: 1,
        path: "/prices/",
        name: <FormattedMessage id="prices" />,
      },
      {
        id: 2,
        path: "/contact/",
        name: <FormattedMessage id="contact" />,
      },
    ],
  }
  render() {
    return (
      <LinkWrapper open={this.props.navbarOpen}>
        {this.state.links.map(item => {
          return (
            <li key={item.id}>
              <LocalizedLink
                to={item.path}
                className="nav-link"
                activeClassName="active"
              >
                {item.name}
              </LocalizedLink>
            </li>
          )
        })}
        <NavbarIcons />
        <NavbarLangSwitch />
      </LinkWrapper>
    )
  }
}

const LinkWrapper = styled.ul`
  background: ${styles.veryDarkGrey};
  height: ${props => (props.open ? "210px" : "0px")};
  margin: 0;
  overflow: ${props => (props.open ? "visible" : "hidden")};
  ${styles.transform({ property: "height" })};
  li {
    list-style-type: none;
  }
  .nav-link {
    cursor: pointer;
    position: relative;
    color: ${styles.mainWhite};
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    text-transform: capitalize;
    ${styles.transform({ property: "padding-left" })};
    &:hover {
      padding: 0.5rem 1rem 0.5rem 1.4rem;
    }
  }

  @media (min-width: ${styles.smallScreenBreakpoint}) {
    background: transparent;
    height: auto;
    display: flex;
    margin-left: auto;
    margin-right: 2rem;
    overflow: visible;
    .nav-link {
      padding: 0.5rem 0.3rem;
      margin-right: 0.8rem;
      ${styles.transform({ property: "color" })};
      color: ${styles.mainWhite};
      &:hover {
        color: grey;
      }
      &:hover,
      &.active {
        padding: 0.5rem 0.3rem 0.3rem;
        border-bottom: solid ${styles.mainWhite} 0.1rem;
      }
    }
  }
`
