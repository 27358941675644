import React from "react"
import { FaWifi, FaParking, FaTv } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

export default function AdditionalInfo(props) {
  const defaultInfos = [
    {
      id: 0,
      icon: <FaWifi className="icon" />,
      info: <FormattedMessage id="wifiInfo" />,
    },
    {
      id: 1,
      icon: <FaParking className="icon" />,
      info: <FormattedMessage id="parkingInfo" />,
    },
    {
      id: 2,
      icon: <FaTv className="icon" />,
      info: <FormattedMessage id="loungeInfo" />,
    },
  ]
  const infos = props.infos || defaultInfos
  return (
    <AdditionalInfoWrapper className={props.className}>
      {infos.map(item => (
        <span key={item.id} className="info">
          {item.icon ? item.icon : ""} {item.info}
        </span>
      ))}
    </AdditionalInfoWrapper>
  )
}

const AdditionalInfoWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  .icon {
    position: relative;
    top: 0.2rem;
    right: 0.5rem;
  }
  .info {
    padding: 0.3rem 0;
  }
`
