import React from "react"
import { createGlobalStyle } from "styled-components"
import Footer from "./footer/Footer"
import Navbar from "./navbar/Navbar"
import PropTypes from "prop-types"
import { styles } from "../globals"

import { IntlProvider, addLocaleData } from "react-intl"

//When adding/removing a language don't forget to change the files in folders i118n/ and constants/
// Locale data
import enData from "react-intl/locale-data/en"
import hrData from "react-intl/locale-data/hr"
import deData from "react-intl/locale-data/de"

// Messages
import en from "../i18n/en.json"
import hr from "../i18n/hr.json"
import de from "../i18n/de.json"

const messages = { en, hr, de }

addLocaleData([...enData, ...hrData, ...deData])

const Layout = ({ locale, children }) => {
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <React.Fragment>
        <GlobalStyle />
        <Navbar />
        {children}
        <Footer />
      </React.Fragment>
    </IntlProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const GlobalStyle = createGlobalStyle`
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  color: black;
  background: ${styles.mainBackgroundColor};
}
`

export default Layout
