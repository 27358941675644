import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({
  defaultTitle,
  defaultDescription,
  defaultKeywords,
  pathname,
}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: { title, description, siteUrl, keywords },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        keywords: keywords || defaultKeywords,
        url: `${siteUrl}${pathname || "/"}`,
      }

      return (
        <Helmet title={seo.title}>
          <meta name="description" content={seo.description} />
          <meta name="keywords" content={seo.keywords.join()} />
          {seo.url && <meta property="url" content={seo.url} />}
          {seo.title && <meta property="title" content={seo.title} />}
          {seo.description && (
            <meta property="description" content={seo.description} />
          )}
        </Helmet>
      )
    }}
  />
)

SEO.defaultProps = {
  defaultTitle: "Hostel Ljubas",
  defaultDescription: "Hostel Ljubas, Viškovo near Rijeka",
  defaultKeywords: ["hostel", "Ljubas"],
  pathname: null,
}

export default SEO

const query = graphql`
  query SEOQuery {
    site {
      siteMetadata {
        title
        description
        keywords
        siteUrl: siteUrl
      }
    }
  }
`
