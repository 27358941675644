module.exports = {
  en: {
    path: "en",
    locale: "English",
    default: true,
  },
  hr: {
    path: "hr",
    locale: "Hrvatski",
  },
  de: {
    path: "de",
    locale: "Deutsche",
  },
}
