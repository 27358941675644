import React, { Component } from "react"
import logo from "../../images/hostel-ljubas-logo_transparent.svg"
import { Link } from "gatsby"
import { MdLineWeight } from "react-icons/md"
import styled from "styled-components"
import { styles } from "../../globals"

export default class NavbarHeader extends Component {
  render() {
    const { handleNavbar } = this.props
    return (
      <HeaderWrapper>
        <Link to="/" className="logo">
          <img src={logo} alt="press for home page" />
        </Link>
        <MdLineWeight
          className="toggle-icon"
          onClick={() => {
            handleNavbar()
          }}
        />
      </HeaderWrapper>
    )
  }
}

const HeaderWrapper = styled.div`
  padding: 0.2rem 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    margin-right: auto;
    margin-top: 1.2rem;
  }
  .logo > img {
    max-height: 60px;
  }
  .toggle-icon {
    color: ${styles.mainWhite};
    font-size: 3rem;
    cursor: pointer;
  }
  @media (min-width: 768px) {
    .toggle-icon {
      display: none;
    }
    .logo {
      max-height: 80px;
    }
  }
`
