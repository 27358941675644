import React, { Component } from "react"
import AdditionalInfo from "../AdditionalInfo"
import styled from "styled-components"
import { styles } from "../../globals"
import title from "../../../gatsby-config"

export default class Footer extends Component {
  render() {
    return (
      <FooterWraper>
        <AdditionalInfoToColumn />
        <div className="copyright">
          copyright &copy; {new Date().getFullYear()} {title.siteMetadata.title}
        </div>
      </FooterWraper>
    )
  }
}

const FooterWraper = styled.footer`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: ${styles.veryDarkGrey};
  color: ${styles.lightOrange};
  padding: 2rem 4rem;
  .copyright {
    text-align: center;
    text-transform: capitalize;
  }
  @media (min-width: ${styles.mediumScreenBreakpoint}) {
    .copyright {
      text-align: right;
    }
  }
`

const AdditionalInfoToColumn = styled(AdditionalInfo)`
  & {
    flex-direction: column;
    margin: 0.5rem auto;
  }

  @media (min-width: ${styles.mediumScreenBreakpoint}) {
    display: none;
  }
`
