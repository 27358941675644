import React, { Component } from "react"
import NavbarHeader from "./NavbarHeader"
import NavbarLinks from "./NavbarLinks"
import styled from "styled-components"
import { styles } from "../../globals"

export default class Navbar extends Component {
  state = {
    navbarOpen: false,
    changeNavbar: false,
  }

  componentDidMount = () => {
    window.onscroll = () => {
      if (window.scrollY >= 200) {
        this.setState(() => {
          return {
            changeNavbar: true,
          }
        })
      } else {
        this.setState(() => {
          return {
            changeNavbar: false,
          }
        })
      }
    }
  }

  handleNavbar = () => {
    this.setState(() => {
      return {
        navbarOpen: !this.state.navbarOpen,
      }
    })
  }

  render() {
    return (
      <NavWrapper changeNavbar={this.state.changeNavbar}>
        <NavbarHeader handleNavbar={this.handleNavbar} />
        <NavbarLinks navbarOpen={this.state.navbarOpen} />
      </NavWrapper>
    )
  }
}

const NavWrapper = styled.nav`
  background-color: ${styles.mainNavbarBackgroundColor};
  ${({ changeNavbar }) =>
    changeNavbar ? "box-shadow: 0px 1px 8px #888888" : ""};
  ${styles.transform({ property: "background", time: ".5s" })};
  min-height: 104px;
  @media (min-width: ${styles.smallScreenBreakpoint}) {
    background-color: ${({ changeNavbar }) =>
      changeNavbar ? styles.mainNavbarBackgroundColor : "transparent"};
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9999;
  }
`
