module.exports = {
  siteMetadata: {
    title: `Hostel Ljubas`,
    description: `Hostel Ljubas is the first hostel to be set in Viškovo, near Rijeka. Opatija is 13 km from the hostel, while Rijeka is 7 km from the property. The nearest airport is Pula Airport, 107 km from Hostel Ljubas.`,
    keywords: [
      "Ljubas",
      "Viškovo",
      "Rijeka",
      "rooms",
      "hostel",
      "cheap",
      "affordable",
      "clean",
      "Viskovo",
    ],
    siteUrl: "https://hostel-ljubas.com", // No trailing slash allowed!
    author: `mkoron`,
  },
  plugins: [
    {
      resolve: `gatsby-plugin-styled-components`,
      options: {
        // Add any options here
      },
    },
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `pages`,
        path: `${__dirname}/src/pages/pagesMD/`,
      },
    },
    `gatsby-plugin-advanced-sitemap`,
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    `gatsby-transformer-remark`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#ff5700`,
        theme_color: `#ff5700`,
        display: `minimal-ui`,
        icon: `src/images/hostel-ljubas-icon.png`, // This path is relative to the root of the site.
      },
    },
    {
      resolve: `gatsby-plugin-typography`,
      options: {
        pathToConfigModule: `src/globals/typography`,
      },
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // 'gatsby-plugin-offline',
  ],
}
