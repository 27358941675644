import React, { Component } from "react"
import styled from "styled-components"
import { styles } from "../../globals"

export default class NavbarIcons extends Component {
  state = {
    icons: [
      {
        id: 1,
        icon: (
          <div className="booking-icon">
            <span>B</span>
            <span className="booking-dot">.</span>
          </div>
        ),
        path: "https://bit.ly/2JTiJvB",
      },
    ],
  }
  render() {
    return (
      <IconsWrapper>
        {this.state.icons.map(item => {
          return (
            <a
              href={item.path}
              key={item.id}
              style={{ textDecoration: "none" }}
            >
              {item.icon}
            </a>
          )
        })}
      </IconsWrapper>
    )
  }
}

const IconsWrapper = styled.div`
  margin-left: 0.3rem;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 3rem;
  .icon {
    cursor: pointer;
    margin-top: 0.2rem;
    ${styles.transform({})};
  }
  .booking-icon {
    background: #15357f;
    border-radius: 0px 6px;
    color: white;
    font-weight: 900;
    padding: 0.2rem;
  }
  .booking-dot {
    color: #2f9fe2;
  }
`
